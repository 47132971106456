// Fonts
//
// // Mulish
// @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600&display=fallback");
// // Unna
// @import url("https://fonts.googleapis.com/css2?family=Unna:ital@0;1&display=swap");

// Colors
$theme-colors: (
  "gold": #a0917a,
  "gold-regular": #a0917a,
  "light-gold": #a0917a,
  "medium-gold": #a0917a,
  "dark-gold": #a0917a,
  "extra-dark-gold": #a0917a,
  "brown-gold": #a0917a,
  "dark-brown-gold": #a0917a,
  "brown": #847c78,
  "dark": #363537,
  "light-dark": #25272e,
  "medium-dark": #22242b,
  "extra-dark": #363537,
  "light": #ffffff,
  "lighter-gray": #eeeeee,
  "light-gray": #857e70,
  "gray": #D2D2D2,
  "medium-gray": #858586,
  "line-gold": #a0917a,
  "footer-light": #eee,
  "black": #000000,
  "input-gray": #D0D0D0,
  "dark-gray": rgba(169, 169, 169, 0.3),
  "special1": #627f80,
  "special2": #233C4C
);

// Backgrounds
$bg-upper-header: #363537;
$bg-container: #363537;
$bg-button: #a0917a;
$bg-footer: #22242b;
$bg-outlet-website: #D2D2D2;

// Fonts
//
// Mulish
/*$mulish-font-family: "Mulish", sans-serif;
$mulish-fw-light: 300;
$mulish-fw-regular: 400;
$mulish-fw-semi-bold: 600;*/

// NEW Mulish ==> DM Sans
$mulish-font-family: "DM Sans", sans-serif;
$mulish-fw-light: 400;
$mulish-fw-regular: 400;
$mulish-fw-semi-bold: 500;
$mulish-fw-bold: 700;

@mixin mulish-light {
  font-family: $mulish-font-family;
  font-weight: $mulish-fw-light;
}

@mixin mulish-regular {
  font-family: $mulish-font-family;
  font-weight: $mulish-fw-regular;
}

@mixin mulish-semi-bold {
  font-family: $mulish-font-family;
  font-weight: $mulish-fw-semi-bold;
}

@mixin mulish-bold {
  font-family: $mulish-font-family;
  font-weight: $mulish-fw-semi-bold;
}

// Unna
/*$unna-font-family: "Unna", serif;
$unna-fw-regular: 400;*/

// NEW Unna ==> DM Serif Display 
$unna-font-family: "DM Serif Display", serif;
$unna-fw-regular: 400;

@mixin unna-italic {
  font-family: $unna-font-family;
  font-weight: $unna-fw-regular;
  font-style: italic;
}

@mixin unna-regular {
  font-family: $unna-font-family;
  font-weight: $unna-fw-regular;
}

$paddingContainer: 6rem;
$upperHeaderHeight: 50px;
