@import "_fonts";
@import "_common";
@import "_text";
@import "_breakpoints";
@import "_media_queries";
@import "swiper_custom_common";
@import "swiper_experience_carousel";
@import "swiper_brand_carousel";
@import "swiper_head_carousel";
@import "swiper_club_carousel";
@import "swiper_home_carousel";
@import "swiper_img_carousel";
@import "swiper_calendar_carousel";
@import "magazine_swiper";
@import "datepicker";
@import "swal_custom";
@import "select_custom";

html,
body {
    overscroll-behavior: none;
    overscroll-behavior-block: none;
}
html:focus-within,
body:focus-within {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    background-color: $bg-outlet-website;

    #global-container {
        > div:not(#head) {
            opacity: 0;
            -webkit-transition: opacity .35s ease-in-out;
            -ms-transition: opacity .35s ease-in-out;
            transition: opacity .35s ease-in-out;
            will-change: opacity;
        }
        > div#head {
            #head-of-page > div {
                opacity: 0;
                -webkit-transition: opacity .35s ease-in-out;
                -ms-transition: opacity .35s ease-in-out;
                transition: opacity .35s ease-in-out;
                will-change: opacity;
            }
        }
        &.shown {
            > div#head {
                #head-of-page > div {
                    opacity: 1;
                }
            }
            > div:not(#head) {
                opacity: 1;
            }
        }
    }
}